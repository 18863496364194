<template lang="pug">
Popup
    template(slot="title")
      div(v-if="isEdit") {{ $t('ListsWaste.detailFormEditTitle') }}
      div(v-else) {{ $t('ListsWaste.detailFormAddTitle') }}

    template(slot="content")
      .selectType(v-if="!isEdit")
        SelectedButtons(v-model="type", :data="[{text: $t('ListsWaste.form_type_stock_item'), value: 0 }, { text: $t('ListsWaste.form_type_recipe'), value: 1 }]")
      div.bgc-white.border-gray.p-10.border-radius-6.m-bottom-25(v-show="type === 0")
        div.flexbox.m-bottom-10
          CustomCheckbox.c-dark.font-size-small(
          id="lists-shelf-location-detail-onhand-selected"
          :label="$t('ListsRequestOrder.formField_checkSuggestionStockItem')"
          v-model="suggestFromStockItem")
        p.c-light.font-size-small {{ $t('ListsRequestOrder.formField_checkSuggestionStockItemDescription') }}
      loading(v-if="this.$wait.is('setData')")
      form.Form(
        id="lists-waste-detail-form",
        v-else,
        :disabled="suggestFromStockItem"
        @submit.prevent="onSubmitForm")

        .Form-item.required(v-show="type === 0")
          label.Form-item-label {{ $t('ListsWaste.detail_Popup_FormField_StockItemName') }}
          .control
            custom-search(
            ref="firstInput"
            name="stockItem"
            v-model="form.stockItem"
            :data-vv-as="$t('ListsWaste.detail_Popup_FormField_StockItemName')"
            v-validate="{'required': type === 0}"
            :class="{ 'is-danger': veeErrors.has('stockItem') }"
            :disabled="isEdit",
            :noDrop="selectOptions.length === 0 && !form.stockItem"
            :on-search="getOptions"
            :options="selectOptions"
            :directSelectOption="directSelectOption"
            label="name"
            :placeholder="$t('ListsWaste.detail_Popup_FormField_StockItemName_Placeholder')")

            showValidateError(
              v-show="veeErrors.has('stockItem')"
              :errorName="veeErrors.first('stockItem')"
            )

        .Form-item.required(v-show="type === 1")
          RecipeGroupsFilter.m-bottom-20.recipe-group-filter(
            v-if="!isEdit"
            @submitFilter="getRecipeList"
            ref="recipeGroupsSupplierFilter")
          label.Form-item-label {{ $t('ListsWaste.detail_Popup_FormField_RecipeName') }}
          .control
            custom-search(
            name="recipe"
            ref="firstInputRecipe"
            v-model="form.recipe"
            :data-vv-as="$t('ListsWaste.detail_Popup_FormField_RecipeName')"
            v-validate="{'required': type === 1}"
            :class="{ 'is-danger': veeErrors.has('recipe') }",
            :on-search="getRecipeOptions",
            :options="selectedOptionsRecipe",
            :noDrop="selectedOptionsRecipe.length === 0 && !form.recipe"
            :disabled="isEdit"
            label="name",
            :placeholder="$t('ListsWaste.detail_Popup_FormField_RecipeName_Placeholder')"
            @afterSelected="setRecipe")

            showValidateError(
              v-show="veeErrors.has('recipe')"
              :errorName="veeErrors.first('recipe')"
            )

        .Form-item
          label.Form-item-label {{ $t('ListsWaste.detail_Popup_FormField_Quantity') }}
          .control
            customNumberInput(
              name="quantity"
              id="lists-waste-detail-form-quantity"
              :data-vv-as="$t('ListsWaste.detail_Popup_FormField_Quantity')"
              v-validate="{decimal: $getConst('DECIMAL_FOR_QUANTITY'), greater_than:0, max: $getConst('MAX_DIGITS_FOR_QUANTITY')}"
              :error="veeErrors.has('quantity')"
              v-model="form.quantity"
            )

            showValidateError(
              v-show="veeErrors.has('quantity')"
              :errorName="veeErrors.first('quantity')"
            )

        .Form-item.required
          label.Form-item-label
            | {{ $t('ListsWaste.detail_Popup_FormField_BaseUnit') }}
          .control.form-item-select
            Icon(name="icon-down-arrow")
            customSelectInput(
              name="unitId",
              :optionData="filteredUnitList"
              v-model="form.unitId"
              v-validate="'required|is_not:-1'"
              :data-vv-as="$t('ListsWaste.detail_Popup_FormField_BaseUnit')"
              :error="veeErrors.has('unitId')"
              id="lists-waste-detail-form-unit",
              optionIdName="option-request-popup-baseunit"
            )

            showValidateError(
              v-show="veeErrors.has('unitId')"
              :errorName="veeErrors.first('unitId')"
            )
      Modal.ListModal(:isOpen="suggestFromStockItem", @closeModal="suggestFromStockItem = false")
        template(slot="title")
          | {{ $t('ListsRequestOrder.formField_checkSuggestionStockItem') }}
        template(slot="content")
          .Modal-Listitems
            .Search
              .icon-field
                Icon.icon-search(name="icon-search")
              input.txt(
                v-model="search",
                id="search-lists-shelf-location-detail-stock-onhand"
                :placeholder="$t('ListsInventory.detail_stockOnHandSearch')"
                @input="getStockItemList")

            itemGroupList.m-bottom-20.item-group-filter(
              groupType="itemGroup"
              :useUnselectedList="true"
              :useCustomEmptyState="true"
              :customEmptyState="!groupFilter.length ? $t('ListsRequestOrder.detail_Popup_itemGroupSelection') : null"
              @submitFilter="getListWithGroupFilter"
              ref="inventoryListsDetailSuggesOnHandFilter"
            )
            .row.header
              .col
                CustomCheckbox(
                  id="popup-checkbox-header-stock-item-name-lists-shelf-location"
                  :disabled="!stockItems.length"
                  :label="$t('ListsInventory.detail_Popup_FormField_StockItemName')",
                  @change="toggleAll")
              .col(id="popup-checkbox-lists-shelf-location-header-unit") {{ $t('ListsInventory.detail_Popup_FormField_Unit') }}
              .col {{ $t('ListsRequestOrder.detail_Popup_FormField_Quantity') }}

            .row(
            v-for="(stock, index) in stockItems",
            :key="stock.id")
              .col
                CustomCheckbox(
                  :id="`popup-checkbox-detail-stock-item-name-${index}`"
                  :label="stock.name",
                  v-if="stockItems.length"
                  :useTooltipForLabel="true"
                  :checked="selectedItems.indexOf(stock) !== -1",
                  @change="isChecked => onItemSelect(isChecked,stock)")
              .col(v-if="stockItems.length" :id="`popup-checkbox-detail-unit-${index}`")
                | {{ findUnit(stock.baseUnitId).name }}
              
              .col
                customNumberInput(
                  name="quantitySuggesStockItem"
                  :data-vv-as="$t('ListsRequestOrder.detail_Popup_FormField_quantity')"
                  v-validate="'greater_than:-1'"
                  :error="veeErrors.has('quantitySuggesStockItem')"
                  v-model="stock.quantity"
                )
              
            
        template(slot="footer")
          .modalFooter
            Button.uppercase.addButton(
              primary
              size="medium"
              id="popup-checkbox-detail-submit-lists-shelf-location"
              variant="full"
              :disabled="selectedItems.length === 0",
              @click="addNewDetail")
              span {{ $t('ListsInventory.detail_Popup_FormField_Add') }}

    template(slot="footer")
      div.add-other-items-checkbox-container
        CustomCheckbox(
        v-if="!isEdit"
        id="checkbox-waste-liste-detail-save-and-new"
        :label="$t('Global.formSaveAndNewText', { form: $t('ListsWaste.detail_Popup_Name') })"
        v-model="saveAndNew")

      Button.uppercase(
        primary
        size="large"
        type="submit"
        id="lists-waste-detail"
        variant="full"
        form="lists-waste-detail-form",
        :disabled="isRunAction",
        :isLoading="isRunAction"
      )
        span(v-if="isEdit") {{ $t('ListsWaste.detailFormEditTitle') }}
        span(v-else) {{ $t('ListsWaste.detailFormAddTitle') }}

</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'
//
import itemGroupList from '@/view/global/business-filters/groups.vue'
import RecipeGroupsFilter from '@/view/global/business-filters/recipe-groups.vue'

export default {
  name: 'ListsWasteDetailForm',
  props: {
    status: {
      type: String,
      default: 'new'
    }
  },
  data () {
    return {
      type: 0,
      selectOptions: [],
      selectedOptionsRecipe: [],
      saveAndNew: true,
      detailObject: {},
      recipeUnitId: null,
      recipeQuantity: null,
      search: null,
      groupFilter: [],
      suggestFromStockItem: false,
      form: {
        stockItem: null,
        recipe: null,
        quantity: null,
        unitId: '-1'
      },
      recipeGroupsFilter: [],
      stockItems: [],
      selectedItems: []
    }
  },
  components: {
    itemGroupList,
    RecipeGroupsFilter
  },
  computed: {
    ...mapGetters('Units', [
      'findUnit',
      'getRelatedUnitList'
    ]),
    ...mapGetters('RecipeGroups', {
      recipeGroupList: 'permittedRecipeGroups'
    }),
    directSelectOption () {
      return this.selectOptions.length === 1 &&
      this.selectOptions[0].matchType === 2
        ? this.selectOptions[0]
        : {}
    },
    isEdit () {
      return this.status === 'edit'
    },
    isRunAction () {
      return this.$wait.is(['createWasteListeDetail', 'updateWasteListeDetail', 'listsWasteDetailSubmit'])
    },
    filteredUnitList () {
      if ((this.type === 0 && !this.form.stockItem) || (this.type === 1 && !this.form.recipe)) return []
      const selectedFieldUnit = this.type === 0 ? this.form.stockItem.unitId : this.form.recipe.unitId
      const baseUnitId = this.isEdit
        ? this.findUnit(this.form.unitId).baseUnitId
        : this.findUnit(selectedFieldUnit).baseUnitId
      const isRecipeUnit = this.type === 1
      return this.getRelatedUnitList(baseUnitId, isRecipeUnit)
    },
    isAllChecked () {
      return this.selectedItems.length === this.stockItems.length
    },
    selectedStockItem () {
      return [{ stockItemId: this.form.stockItem.id, unitId: this.form.unitId, quantity: this.form.quantity || null}]
    },
    detailList () {
      return this.selectedItems.map(item => {
        return {
          stockItemId: item.id,
          unitId: item.baseUnitId,
          quantity: item.quantity
        }
      })
    }
  },
  methods: {
    ...mapActions('Stock', ['searchStockItem', 'suggestStockItems']),
    ...mapActions('Recipes', ['searchRecipeItemByName', 'get_RECIPE_HEADER']),
    ...mapActions('Notify', ['notifyShow']),
    ...mapActions('ListsWaste', [
      'createWasteListeDetail',
      'updateWasteListeDetail',
      'getWasteListsDetailInfo'
    ]),

    setRecipe (option) {
      this.get_RECIPE_HEADER(option.id)
        .then(res => {
          this.form.recipe = res.data.recipeHeader
          this.setUnit(this.form.recipe.unitId)
        })
    },

    toggleAll () {
      if (this.isAllChecked) {
        this.selectedItems = []
      } else {
        this.selectedItems = this.stockItems
      }
    },

    getListWithGroupFilter ({list, dontSubmit}) {
      if (dontSubmit) this.groupFilter = list
      else {
        this.groupFilter = list
        this.getStockItemList()
        if (!this.form.stockItem) return
        this.form.stockItem = null
      }
    },

    getRecipeList ({list, dontSubmit}) {
      if (dontSubmit) this.recipeGroupsFilter = list
      else {
        this.recipeGroupsFilter = list
        if (!this.form.recipe) return
        this.form.recipe = null
      }
    },

    getStockItemList() {
      if(this.search && this.search.length < 2) return
      if(!this.search && !this.groupFilter.length) {
        this.stockItems = []
        return
      }
      const payload = {
        itemGroupIds: this.groupFilter.map(i => i.id),
        pageSize: 9999999,
        pageNumber: 1,
        name: this.search
      }
      this.suggestStockItems(payload).then(res => {
        if (res) {
          this.stockItems = res.data.stockItemPageList
          this.stockItems.map(i => i.quantity = 0)
        }
      })
    },  

    onItemSelect (isChecked, stock) {
      if (isChecked) {
        this.selectedItems.push(stock)
      } else {
        this.selectedItems.splice(this.selectedItems.indexOf(stock), 1)
      }
    },

    getOptions (search) {
      this.searchStockItem(
        {
          Text: search,
          types: [1, 2, 3],
          itemGroupIds: this.groupFilter ? this.groupFilter.map(item => item.id) : []
        })
        .then(res => {
          if (res) {
            this.selectOptions = res.data.items
          }
        })
    },

    getRecipeOptions (search) {
      this.searchRecipeItemByName(
        {
          name: search,
          type: 1,
          recipeGroupIds: this.recipeGroupsFilter.length === this.recipeGroupList.length ? [] : this.recipeGroupsFilter.map(item => item.id)
        })
        .then(res => {
          if (res.status === 200) {
            this.selectedOptionsRecipe = res.data.recipeHeaderList
          }
        })
    },
    async formFocus () {
      if (this.isEdit) return false
      const firstInput = this.type === 0 ? this.$refs.firstInput : this.$refs.firstInputRecipe
      await firstInput.$refs.search.focus()
    },
    setUnit (unitId) {
      this.form.unitId = unitId
    },
    formReset () {
      this.form = {
        stockItem: null,
        recipe: null,
        quantity: null,
        unitId: '-1'
      }
      this.selectOptions = []
      this.selectedOptionsRecipe = []
    },
    closePopup () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },
    async addNewDetail () {
      let payload
      if (this.type === 0) {
        payload = {
          wasteListHeaderId: this.$route.params.id,
          wasteListDetails: this.suggestFromStockItem ? this.detailList : this.selectedStockItem
        }
      }

      if (this.type === 1) {
        payload = {
          wasteListHeaderId: this.$route.params.id,
          recipeHeaderId: this.form.recipe.id, 
          recipeUnitId: this.form.recipe.unitId, 
          recipeQuantity: this.form.quantity || null
        }
         
      }
      const response = await this.createWasteListeDetail(payload)
      if (response) {
        const message = this.$t('ListsWaste.successDetailCreateMessage')
        this.notifyShow({ message })
        if (!this.saveAndNew) {
          this.closePopup()
        } else {
          this.formReset()
          this.$nextTick(() => {
            this.$validator.reset()
            this.formFocus()
          })
        }
      }
    },

    async updateDetail () {
      const params = {
        quantity: this.form.quantity || null,
        unitId: this.form.unitId,
        id: this.form.id
      }
      if (this.type === 0) {
        params.stockItemId = this.form.stockItem.id
      } else if (this.type === 1) {
        params.recipeHeaderId = this.form.recipe.id
      }
      const response = await this.updateWasteListeDetail({
        wasteListHeaderId: this.$route.params.id,
        params
      })
      if (response) {
        const message = this.$t('ListsWaste.successDetailUpdateMessage')
        this.notifyShow({ message })
        this.closePopup()
      }
    },

    onSubmitForm: vueWaitLoader(async function () {
      const hasNotErrors = await this.$validator.validateAll()
      if (!hasNotErrors) return false
      if (this.isEdit) {
        await this.updateDetail()
      } else {
        await this.addNewDetail()
      }
    }, 'listsWasteDetailSubmit'),

    async setData () {
      const res = await this.getWasteListsDetailInfo(this.$route.params.detailId)
      if (res) {
        this.detailObject = await {...res.data.wasteListDetail}
        this.type = await this.detailObject.stockItemId ? 0 : this.detailObject.recipeHeaderId ? 1 : ''
        this.form.stockItem = await this.type === 0 ? {
          id: this.detailObject.stockItemId,
          name: this.detailObject.name
        } : null
        this.form.recipe = await this.type === 1 ? {
          id: this.detailObject.recipeHeaderId,
          name: this.detailObject.name
        } : null
        this.form.quantity = await this.detailObject.quantity
        this.form.unitId = await this.detailObject.unitId
        this.form.id = await this.detailObject.id
      }
    }
  },
  watch: {
    'form.stockItem' (val) {
      if (!val || this.isEdit) return false
      this.setUnit(val.unitId)
    }
  },
  async mounted () {
    this.$watch('type', () => {
      this.$validator.reset()
      this.formReset()
      this.formFocus()
    })
    if (this.isEdit) {
      this.$wait.start('setData')
      await this.setData()
      this.$wait.end('setData')
    } else {
      this.formFocus()
    }
  }
}
</script>

<style lang="scss" scoped>

  @import '~@/view/pages/Lists/styles/form-modal.scss';

  .item-group-filter{
    max-width: 320px !important;
  }
  .recipe-group-filter {
    max-width: 320px !important;
  }

  .ListModal {
    .Modal-Listitems {
      .row {
         grid-template-columns: repeat(3, 1fr);
      }
    }
  }
</style>
